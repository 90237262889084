import Vue from "vue";
import VueRouter from "vue-router";

import HomeView from "@/views/HomeView.vue";
import PrivacyPolicyView from "@/views/PrivacyPolicyView.vue";
import TermsOfUseView from "@/views/TermsOfUseView.vue";
import DragonpaySuccessPageView from "@/views/DragonpaySuccessPageView.vue";
import DigicoopDragonpaySuccessPageView from "@/views/DigicoopDragonpaySuccessPageView.vue";
import GCashSuccessPageView from "@/views/GCashSuccessPageView.vue";
import DigicoopGCashSuccessPageView from "@/views/DigicoopGCashSuccessPageView.vue";
import GCashErrorPageView from "@/views/GCashErrorPageView.vue";
import Ipay88SuccessPageView from "@/views/Ipay88SuccessPageView.vue";
import Ipay88ErrorPageView from "@/views/Ipay88ErrorPageView.vue";
import TwoCTwoPSuccessPageView from "@/views/2c2pSuccessPageView.vue";
import TwoCTwoPErrorPageView from "@/views/2c2pErrorPageView.vue";
import AltPayPageView from "@/views/AltPayPageView.vue";
import MayaSuccessPageView from "@/views/MayaSuccessPageView.vue";
import MayaErrorPageView from "@/views/MayaErrorPageView.vue";
import BrankasSuccessPageView from "@/views/BrankasSuccessPageView.vue";
import BrankasErrorPageView from "@/views/BrankasErrorPageView.vue";
import PaypalSuccessPageView from "@/views/PaypalSuccessPageView.vue";
import PaypalErrorPageView from "@/views/PaypalErrorPageView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicyView,
  },
  {
    path: "/terms-of-use",
    name: "terms-of-use",
    component: TermsOfUseView,
  },
  {
    path: "/dragonpay/success",
    name: "dragonpay-success-page",
    component: DragonpaySuccessPageView,
  },
  {
    path: "/digicoop/v2/dragonpay/success",
    name: "digicoop-dragonpay-success-page",
    component: DigicoopDragonpaySuccessPageView,
  },
  {
    path: "/gcash/success",
    name: "gcash-success-page",
    component: GCashSuccessPageView,
  },
  {
    path: "/digicoop/v2/gcash/success",
    name: "digicoop-gcash-success-page",
    component: DigicoopGCashSuccessPageView,
  },
  {
    path: "/gcash/error",
    name: "gcash-error-page",
    component: GCashErrorPageView,
  },
  {
    path: "/ipay88/success",
    name: "ipay88-success-page",
    component: Ipay88SuccessPageView,
  },
  {
    path: "/ipay88/error",
    name: "ipay88-error-page",
    component: Ipay88ErrorPageView,
  },
  {
    path: "/2c2p/success",
    name: "2c2p-success-page",
    component: TwoCTwoPSuccessPageView,
  },
  {
    path: "/2c2p/error",
    name: "2c2p-error-page",
    component: TwoCTwoPErrorPageView,
  },
  {
    path: "/altpay",
    name: "altpay-page",
    component: AltPayPageView,
  },
  {
    path: "/maya/success",
    name: "maya-success-page",
    component: MayaSuccessPageView,
  },
  {
    path: "/maya/error",
    name: "maya-error-page",
    component: MayaErrorPageView,
  },
  {
    path: "/brankas/success",
    name: "brankas-success-page",
    component: BrankasSuccessPageView,
  },
  {
    path: "/brankas/error",
    name: "brankas-error-page",
    component: BrankasErrorPageView,
  },
  {
    path: "/paypal/success",
    name: "paypal-success-page",
    component: PaypalSuccessPageView,
  },
  {
    path: "/paypal/error",
    name: "paypal-error-page",
    component: PaypalErrorPageView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
