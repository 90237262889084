<template>
  <div class="paypal-error">
    <app-paypal-error-page />
  </div>
</template>

<script>
import PaypalErrorPageComponent from "@/components/PaypalErrorPage/PaypalErrorPageComponent.vue";

export default {
  name: "PaypalErrorPageView",
  components: {
    "app-paypal-error-page": PaypalErrorPageComponent,
  },
};
</script>
