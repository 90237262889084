<template>
  <div class="paypal-success">
    <app-paypal-success-page />
  </div>
</template>

<script>
import PaypalSuccessPageComponent from "@/components/PaypalSuccessPage/PaypalSuccessPageComponent.vue";

export default {
  name: "PaypalSuccessPageView",
  components: {
    "app-paypal-success-page": PaypalSuccessPageComponent,
  },
};
</script>
